import React, { useState, useEffect } from 'react';

function DataPage() {
  const [lottoData, setLottoData] = useState([]);
  const [displayMessage, setDisplayMessage] = useState('');
  const [filter, setFilter] = useState('all'); 

  useEffect(() => {
    const fetchData = async () => {
      let url = '/data?filter=' + filter;
      try {
        const response = await fetch(url);
        if (!response.ok) throw new Error(`HTTP error! status: ${response.status}`);
        const data = await response.json();
        setLottoData(data);
      } catch (error) {
        console.error('Error fetching lotto data:', error);
      }
    }
    fetchData();
  }, [filter]);

  const handleButtonClick = (filter, message) => {
    setDisplayMessage(message);
    setFilter(filter); // อัพเดต state filter ซึ่งจะทำให้ useEffect ทำงาน
  };
 
  const convertDateToYYYYMMDD = (dateString) => {
    const date = new Date(dateString);
    let day = date.getDate();
    let month = date.getMonth() + 1;
    let year = date.getFullYear() + 543;

    // แปลงให้เป็นรูปแบบ 2 หลัก
    day = day < 10 ? '0' + day : day;
    month = month < 10 ? '0' + month : month;

    return `${year}${month}${day}`;
  };


  function formatDate(dateString) {
    const date = new Date(dateString);
    let day = date.getDate();
    let month = date.getMonth() + 1;
    let year = date.getFullYear() + 543; // แปลงเป็นปีพุทธศักราช

    // แปลงให้เป็นรูปแบบ 2 หลัก
    day = day < 10 ? '0' + day : day;
    month = month < 10 ? '0' + month : month;

    //const [day, month, year] = dateString.split('/');
    const buddhistYear = parseInt(year, 10);
    const months = ['มกราคม', 'กุมภาพันธ์', 'มีนาคม', 'เมษายน', 'พฤษภาคม', 'มิถุนายน', 'กรกฎาคม', 'สิงหาคม', 'กันยายน', 'ตุลาคม', 'พฤศจิกายน', 'ธันวาคม'];
    const formattedDate = `${day} ${months[parseInt(month, 10) - 1]} ${buddhistYear}`;
    return formattedDate;
  };

    return (
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="award-history-box">
                <h2>ผลสลากกินแบ่งรัฐบาลย้อนหลัง {displayMessage && `(${displayMessage})`}</h2>
                {/* ส่วนของปุ่ม */}
                <div className="mt-4"> {/* ใส่ margin bottom */}
                  <button className="btn btn-primary btn-extra-sm" onClick={() => handleButtonClick('latest36', '36 งวดล่าสุด')}>36 งวดล่าสุด</button>
                  <button className="btn btn-primary btn-extra-sm" onClick={() => handleButtonClick('latest24', '24 งวดล่าสุด')}>24 งวดล่าสุด</button>
                  <button className="btn btn-primary btn-extra-sm" onClick={() => handleButtonClick('currentMonth', 'งวดเฉพาะเดือนปัจจุบัน')}>งวดเฉพาะเดือนปัจจุบัน</button>
                  <button className="btn btn-primary btn-extra-sm" onClick={() => handleButtonClick('nextMonth', 'งวดเฉพาะเดือนถัดไป')}>งวดเฉพาะเดือนถัดไป</button>
                </div>

                <div className="dashboard-panel">
                  <table className="table">
                    {/* ส่วนหัวตาราง */}
                    <thead>
                      <tr>
                        <th>งวด</th>
                        <th>รางวัลที่ 1</th>
                        <th>3 ตัวหน้า</th>
                        <th>3 ตัวหลัง</th>
                        <th>เลขท้าย 2 ตัว</th>
                        <th></th>
                      </tr>
                    </thead>
                    {/* ข้อมูลตาราง */}
                    <tbody>
                      {lottoData.map((item, index) => (
                        <tr key={index}>
                          <td>ผลสลากกินแบ่งรัฐบาล {formatDate(item['date'])}</td>
                          <td>{item['1ST_6NUM']}</td>
                          <td><span style={{ marginRight: '10px' }}>{item['FRONT_3NUM_1']}</span>{item['FRONT_3NUM_2']}</td>
                          <td><span style={{ marginRight: '10px' }}>{item['BACK_3NUM_1']}</span>{item['BACK_3NUM_2']}</td>
                          <td>{item['2NUM']}</td>
                          <td>
                          <a href={`/award-pdf/${convertDateToYYYYMMDD(item['date'])}.pdf`} target="_blank" rel="noopener noreferrer">
                              <i className="fas fa-file-pdf"></i>
                                ใบตรวจรางวัล
                            </a>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
                
              </div>
            </div>
          </div>
        </div>
    );
}

export default DataPage;