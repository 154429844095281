import React, { useState, useEffect } from 'react';
import { Button, Modal, Spinner } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css'; // สำหรับ CSS
console.time("LH");
function LottoHistory() {
  const months = ['มกราคม', 'กุมภาพันธ์', 'มีนาคม', 'เมษายน', 'พฤษภาคม', 'มิถุนายน', 'กรกฎาคม', 'สิงหาคม', 'กันยายน', 'ตุลาคม', 'พฤศจิกายน', 'ธันวาคม'];
  const [lottoData, setLottoData] = useState([]);
  const [displayMessage, setDisplayMessage] = useState('งวดต่อไป');
  const [filter, setFilter] = useState('nextPeriod'); 
  const [isLoading, setIsLoading] = useState(false);

  const [showModal, setShowModal] = useState(false);
  const handleCloseModal = () => setShowModal(false);
  const handleShowModal = () => setShowModal(true); 


  const [availableYears, setAvailableYears] = useState([]);
  const [showYearModal, setShowYearModal] = useState(false);
  const [loadYears, setLoadYears] = useState(false);
  const handleCloseYearModal = () => setShowYearModal(false);
  const handleShowYearModal = () => setShowYearModal(true);
 
  const selectYear = (year) => {
    setFilter(`yearly_${year}`); // ตั้งค่า filter ตามปีที่เลือก
    setDisplayMessage(`ปี ${year + 543}`); // อัพเดตข้อความที่จะแสดง
    handleCloseYearModal(); // ปิด modal
    // คุณอาจต้องดึงข้อมูลสลากกินแบ่งของปีที่เลือกหลังจากนี้
  };

  // Fetch ข้อมูลปี
  /*
  useEffect(() => {
    console.time("LH>UE>LY");
    if (loadYears) {
      const fetchYears = async () => {
        setIsLoading(true);
        let url = '/years';
        try {
          const response = await fetch(url);
          if (!response.ok) throw new Error(`HTTP error! status: ${response.status}`);
          const years = await response.json();
          setAvailableYears(years);
          handleShowYearModal();
        } catch (error) {
          console.error('Error fetching years:', error);
        } finally {
          setIsLoading(false);
          setLoadYears(false); // รีเซ็ตการโหลดข้อมูลปี
        }
      };
      fetchYears();
    }
    console.timeEnd("LH>UE>LY");
  }, [loadYears]); // ขึ้นอยู่กับสถานะ loadYears
*/

useEffect(() => {
  // ดึงข้อมูลวันที่ของงวดต่อไปเมื่อ component ถูกโหลด
  const fetchNextPeriodDate = async () => {
    setIsLoading(true);
    try {
      const response = await fetch('/nextPeriod');
      const data = await response.json();
      if (data && data.perioddate) {
        const nextPeriodDate = formatDate(data.perioddate);
        setDisplayMessage(`งวดต่อไป - ${nextPeriodDate}`);
      }
    } catch (error) {
      console.error('Error fetching next period date:', error);
    } finally {
      setIsLoading(false);
    }
  };

  // เรียกใช้เมื่อ component ถูกโหลดครั้งแรก
  fetchNextPeriodDate();
}, []); // Dependency array เป็นค่าว่าง เพื่อให้รันเพียงครั้งเดียวเมื่อโหลด
  
  const [availableMonths, setAvailableMonths] = useState([]);
  const [showMonthModal, setShowMonthModal] = useState(false);
  const [loadMonths, setLoadMonths] = useState(false);
  const handleCloseMonthModal = () => setShowMonthModal(false);
  const handleShowMonthModal = () => setShowMonthModal(true);

  const selectMonth = (month) => {
    setFilter(`monthy_${month}`); 
    setDisplayMessage(`เดือน ${months[month-1]}`);
    handleCloseMonthModal(); 
    // Fetch lottery data for the selected month
  };

  useEffect(() => {
    console.time("LH>UE>LM");
    if (loadMonths) {
      // Here, you would fetch the months similar to how you fetch years.
      // You'll need to set up a corresponding endpoint in your server code.
      const fetchMonths = async () => {
        setIsLoading(true);
        let url = '/months'; // You will need to implement this endpoint on the server
        try {
          const response = await fetch(url);
          if (!response.ok) throw new Error(`HTTP error! status: ${response.status}`);
          const months = await response.json();
          setAvailableMonths(months);
          handleShowMonthModal();
        } catch (error) {
          console.error('Error fetching months:', error);
        } finally {
          setIsLoading(false);
          setLoadMonths(false);
        }
      };
      fetchMonths();
    }
    console.timeEnd("LH>UE>LM");
  }, [loadMonths]);

  // Fetch ข้อมูลสลากกินแบ่งตาม filter
  useEffect(() => {
    console.time("LH>UE>FT");
    if (filter && filter !== 'specifyYear' && filter !== 'specifyMonth') {
      setIsLoading(true);
      let url;
      if (filter.startsWith('yearly_')) {
        const year = filter.split('_')[1]; // แยกเพื่อได้ปี
        url = `/data?filter=${filter.split('_')[0]}&year=${year}`; // ปรับปรุง URL ให้สอดคล้องกับ filter 
      } else if (filter.startsWith('monthy_')) {
          const month = filter.split('_')[1]; // แยกเพื่อได้ปี
          url = `/data?filter=${filter.split('_')[0]}&month=${month}`; // ปรับปรุง URL ให้สอดคล้องกับ filter
      } else {
        url = `/data?filter=${filter}`;
      }
      
      const fetchData = async () => {
        try {
          const response = await fetch(url);
          if (!response.ok) throw new Error(`HTTP error! status: ${response.status}`);
          const data = await response.json();
          console.log("data:" + data);
          setLottoData(data);
        } catch (error) {
          console.error('Error fetching lotto data:', error);
        } finally {
          setIsLoading(false);
        }
      };
      fetchData();
    }
    console.timeEnd("LH>UE>FT");
  }, [filter]); // ขึ้นอยู่กับสถานะ filter
  

  const handleButtonClick = (selectedFilter, message) => {
    if (selectedFilter === 'currentPeriod') {
      setIsLoading(true);
      fetch('/latestPeriod')
        .then(response => response.json())
        .then(data => {
          const latestPeriodDate = formatDate(data.perioddate);
          setDisplayMessage(`งวดล่าสุด - ${latestPeriodDate}`);
          setFilter(selectedFilter);
        })
        .catch(error => console.error('Error:', error))
        .finally(() => setIsLoading(false));
    } else if (selectedFilter === 'nextPeriod') {
      setIsLoading(true);
      fetch('/nextPeriod')
        .then(response => response.json())
        .then(data => {
          const nextPeriodDate = formatDate(data.perioddate);
          setDisplayMessage(`งวดต่อไป - ${nextPeriodDate}`);
          setFilter(selectedFilter);
        })
        .catch(error => console.error('Error:', error))
        .finally(() => setIsLoading(false));
    } else if (selectedFilter === 'specifyYear') {
      // ตั้งค่าให้โหลดข้อมูลปี
      setLoadYears(true);
    } else if (selectedFilter === 'specifyMonth') {
      setLoadMonths(true); // โหลดข้อมูลเดือน
    } else {
      // อัพเดต filter และแสดงข้อความ
      setDisplayMessage(message);
      setFilter(selectedFilter);
    }
  };
 
  const convertDateToYYYYMMDD = (dateString) => {
    const date = new Date(dateString);
    let day = date.getDate();
    let month = date.getMonth() + 1;
    let year = date.getFullYear() + 543;

    // แปลงให้เป็นรูปแบบ 2 หลัก
    day = day < 10 ? '0' + day : day;
    month = month < 10 ? '0' + month : month;
    
    return `${year}${month}${day}`;
  };

  function formatDate(dateString) {
    const date = new Date(dateString);
    let day = date.getDate();
    let month = date.getMonth() + 1;
    let year = date.getFullYear() + 543; // แปลงเป็นปีพุทธศักราช

    // แปลงให้เป็นรูปแบบ 2 หลัก
    day = day < 10 ? '0' + day : day;
    month = month < 10 ? '0' + month : month;

    //const [day, month, year] = dateString.split('/');
    const buddhistYear = parseInt(year, 10);
    const months = ['มกราคม', 'กุมภาพันธ์', 'มีนาคม', 'เมษายน', 'พฤษภาคม', 'มิถุนายน', 'กรกฎาคม', 'สิงหาคม', 'กันยายน', 'ตุลาคม', 'พฤศจิกายน', 'ธันวาคม'];
    const formattedDate = `${day} ${months[parseInt(month, 10) - 1]} ${buddhistYear}`;
    return formattedDate;
  }

  return (
    <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="award-history-box">
              <h2>ผลสลากกินแบ่งรัฐบาลย้อนหลัง {displayMessage && `(${displayMessage})`}</h2>
              {isLoading && (filter === 'specifyYear' && filter === 'specifyMonth') ? (
                <div className="text-center">
                  <Spinner animation="border" role="status">
                    <span className="sr-only">Loading...</span>
                  </Spinner>
                </div>
              ) : (
                <>
        
                {/* ส่วนของปุ่ม */}
                <div className="mt-3"> {/* ใส่ margin bottom */}
                  <button className="btn btn-primary btn-extra-sm" onClick={() => handleButtonClick('last36', '36 งวดล่าสุด')}>36 งวดล่าสุด</button>
                  <button className="btn btn-primary btn-extra-sm" onClick={() => handleButtonClick('last24', '24 งวดล่าสุด')}>24 งวดล่าสุด</button>
                  <button className="btn btn-primary btn-extra-sm" onClick={() => handleButtonClick('currentMonth', 'เดือนปัจจุบัน')}>เดือนปัจจุบัน</button>
                  <button className="btn btn-primary btn-extra-sm" onClick={() => handleButtonClick('nextMonth', 'เดือนถัดไป')}>เดือนถัดไป</button>
                  <button className="btn btn-primary btn-extra-sm" onClick={() => handleButtonClick('currentPeriod', 'งวดล่าสุด')}>งวดล่าสุด</button>
                  <button className="btn btn-primary btn-extra-sm" onClick={() => handleButtonClick('nextPeriod', 'งวดต่อไป')}>งวดต่อไป</button>
                  <button className="btn btn-success btn-extra-sm" onClick={() => handleButtonClick('specifyYear', 'ปี')}>ปี</button>
                  <button className="btn btn-success btn-extra-sm" onClick={() => handleButtonClick('specifyMonth', 'เดือน')}>เดือน</button>
                </div>

                <div className="dashboard-panel">
                  <table className="table">
                    {/* ส่วนหัวตาราง */}
                    <thead>
                      <tr>
                        <th>งวด</th>
                        <th>รางวัลที่ 1</th>
                        <th>3 ตัวหน้า</th>
                        <th>3 ตัวหลัง</th>
                        <th>เลขท้าย 2 ตัว</th>
                        <th></th>
                      </tr>
                    </thead>
                    {/* ข้อมูลตาราง */}
                    <tbody>
                      {lottoData.map((item, index) => (
                        <tr key={index}>
                          <td>ผลสลากกินแบ่งรัฐบาล {formatDate(item['date'])}</td>
                          <td>{item['1ST_6NUM']}</td>
                          <td><span style={{ marginRight: '10px' }}>{item['FRONT_3NUM_1']}</span>{item['FRONT_3NUM_2']}</td>
                          <td><span style={{ marginRight: '10px' }}>{item['BACK_3NUM_1']}</span>{item['BACK_3NUM_2']}</td>
                          <td>{item['2NUM']}</td>
                          <td>
                            {item['1ST_6NUM'] !== '' ? (
                              <a href={`/award-pdf/${convertDateToYYYYMMDD(item['date'])}.pdf`} target="_blank" rel="noopener noreferrer">
                                <i className="fas fa-file-pdf"></i>
                                <span className="hide-on-mobile"> ใบตรวจรางวัล</span>
                              </a>
                            ) : (
                              <button variant="primary" onClick={handleShowModal} className="btn btn-primary btn-extra-sm">
                                <i className="fas fa-info-circle fa-sm"></i> 
                                <span className="hide-on-mobile"> ข้อมูล</span>
                              </button>
                            )}
                          </td>
                        </tr>
                      ))}
                    </tbody>

                    <Modal show={showModal} onHide={handleCloseModal} className="custom-modal">
                      <Modal.Header closeButton>
                        <Modal.Title>ไม่พบใบตรวจรางวัล</Modal.Title>
                      </Modal.Header>
                      <Modal.Body>
                          ไม่พบใบตรวจรางวัลสำหรับผลสลากกินแบ่งรัฐบาลงวดนี้<br />
                          <br />
                          เนื่องด้วยสถานการณ์โควิด ทำให้งวดประวันที่ 1 เมษายน 2563 เลื่อนไปออกไปวันที่ 16 พฤษภาคม 2563<br />
                          และทำการยกเลิกการออกสลากกินแบ่งบางงวด ดังนี้<br />
                          <br />
                          งวดประวันที่ 1 เมษายน 2563 (มีการขายสลากตามปกติ)<br />
                          งวดประวันที่ 16 เมษายน 2563 (ยกเลิกการขายสลาก)<br />
                          งวดประวันที่ 2 พฤษภาคม 2563 (ยกเลิกการขายสลาก)<br /> 
                          งวดประวันที่ 16 พฤษภาคม 2563 (ยกเลิกการขายสลาก)<br /> 
                          <br />
                          แต่ระบบจะยึดวันออกรางวัลสลาก คือ วันที่ 1 เมษายน 2563 ถึงแม้จะนำไปออกวันที่ 16 พฤษภาคม 2563
                      </Modal.Body>
                      <Modal.Footer>
                        <Button variant="secondary" onClick={handleCloseModal}>
                          ปิด
                        </Button>
                      </Modal.Footer>
                    </Modal>

                    <Modal show={showYearModal} onHide={handleCloseYearModal}>
                      <Modal.Header closeButton>
                        <Modal.Title>เลือกปีที่ต้องการดูผลสลากกินแบ่ง</Modal.Title>
                      </Modal.Header>
                      <Modal.Body>
                        {isLoading ? (
                          <Spinner animation="border" role="status">
                            <span className="visually-hidden">Loading...</span>
                          </Spinner>
                        ) : (
                          availableYears.map(year => (
                            <button className="yearly-btn" key={year} onClick={() => selectYear(year)}>
                              {year + 543}
                            </button>
                          ))
                        )}
                      </Modal.Body>
                    </Modal>

                    <Modal show={showMonthModal} onHide={handleCloseMonthModal}>
                      <Modal.Header closeButton>
                        <Modal.Title>เลือกปีที่ต้องการดูผลสลากกินแบ่ง</Modal.Title>
                      </Modal.Header>
                      <Modal.Body>
                        {isLoading ? (
                          <Spinner animation="border" role="status">
                            <span className="visually-hidden">Loading...</span>
                          </Spinner>
                        ) : (
                          availableMonths.map(month => (
                            <button className="monthy-btn" key={month} onClick={() => selectMonth(month)}>
                              {months[parseInt(month, 10) - 1]}
                            </button>
                          ))
                        )}
                      </Modal.Body>
                    </Modal>

                  </table>
                </div>
              
                </>
            )}
      </div>
            </div>
          </div>
        </div>
    );
}
console.timeEnd("LH");
export default LottoHistory;