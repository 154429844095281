import React, { useState } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';

const Menu = () => {
  const [showContactPopup, setShowContactPopup] = useState(false);

  const handleContactClick = (e) => {
    e.preventDefault();
    setShowContactPopup(true);
  };

  const handleCopyEmail = () => {
    setShowContactPopup(false);
    alert('Email address copied to clipboard!');
  };

  return (
    <nav className="navbar navbar-expand-lg navbar-light bg-light">
      <div className="container-fluid">
        <a className="navbar-brand" href="#sec">
          numxo
        </a>
        <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarNavDropdown">
          <ul className="navbar-nav ms-auto">
            <li className="nav-item">
              <a className="nav-link" href="#contact" onClick={handleContactClick}>Contact</a>
            </li>
          </ul>
        </div>
      </div>
      
      {showContactPopup && (
        <div className="contact-popup">
          <p>Email: contact@numxo.com
            <CopyToClipboard text="contact@numxo.com" onCopy={handleCopyEmail}>
              <button><i className="fas fa-copy"></i></button>
            </CopyToClipboard>
          </p>
        </div>
      )}
    </nav>
  );
};

export default Menu;
