import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import 'bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css'; // สำหรับ CSS
import './App.css';
import Menu from './Menu';
import LottoHistory from './LottoHistory';
import LottoStat from './LottoStat';
import DataPage from './DataPage'; // ตรวจสอบว่าได้นำเข้า DataPage มาแล้ว

function App() {

  // สร้างอาร์เรย์ของชื่อคลาสสำหรับสี
  const colorClasses = ['color-1', 'color-2', 'color-3', 'color-4', 'color-5'];

  // สร้างฟังก์ชั่นเพื่อเลือกคลาสสีโดยสุ่ม
  const getRandomColorClass = () => {
    return colorClasses[Math.floor(Math.random() * colorClasses.length)];
  };

  // สร้างองค์ประกอบตัวเลขโดยใช้ข้อมูลสุ่ม
  const backgroundNumbers = Array.from({ length: 50 }, () => ({
    number: Math.floor(Math.random() * 10),
    colorClass: getRandomColorClass(),
    opacity: Math.random(),
    x: Math.floor(Math.random() * window.innerWidth),
    y: Math.floor(Math.random() * window.innerHeight),
  }));

  // ฟังก์ชันสำหรับจัดการกับการคลิกลิงค์
  const handleLinkClick = (e) => {
    e.preventDefault(); // ป้องกันลิงค์จากการนำทางไปยัง URL ปกติ
    window.alert("กำลังดำเนินการ"); // แสดงข้อความแจ้งเตือน
  };

  
  return (
    <Router>
    <div className="App">
      <Menu />
      <header className="App-header">

      <div className="background-numbers">
        {backgroundNumbers.map((num, index) => (
          <span
            key={index}
            className={num.colorClass}
            style={{
              opacity: num.opacity,
              left: `${num.x}px`,
              top: `${num.y}px`,
            }}
          >
            {num.number}
          </span>
        ))}
      </div>

      <div className="container">
        <div className="row">
          <div className="col-12 after-award">
            <div className="award-current-box">
              <div className="row row1">
                <div className="col-12 col-md-6 col-lg-8">
                  <h1>ผลการออกรางวัลสลากกินแบ่งรัฐบาล</h1>
                  <h2>งวดประจำวันที่ 16 กันยายน 2567</h2>
                </div>
                <div className="col-12 col-md-6 col-lg-4 d-none d-md-block">
                  <div className="row button-home">
                    <div className="col-6">
                      <a href="/mission/reward-payment/check-reward" onClick={handleLinkClick}>
                        <div className="button-secoundary">
                          ตรวจผลรางวัล
                        </div>
                      </a>
                    </div>
                    <div className="col-6">
                      <a href="/award-pdf/current.pdf" target="_blank" rel="noopener noreferrer">
                        <div className="button-secoundary">
                          <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAWCAYAAADafVyIAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAIGNIUk0AAHolAACAgwAA+f8AAIDpAAB1MAAA6mAAADqYAAAXb5JfxUYAAAF4SURBVHja7NU9axVREIDhZ6/BZIVgwEJSWKiNImhjAnYiLIjYiIWlpLCxzULIP5CwpYIoiKUIIrZubyFXSEBUsLCJhUoQEd2L+Wxm4bLCZTfRLlPOmZn3zMc5k0zMv9RSEtzFA6zUyqrIRjr1tJfruI3lDj7GOtr+xGYXQJcMatn+3wD7gH3AX4ATMXo3GmcpbuLMCP/5NC9nmso0L9+nefmoBtwKwJOG3VU8xlucHvouar9nKPC6EfwCTmEuzcsjPQzC8U0D8Bzf4+X2cTgukuApLofdYsNvgB9Yx2BUDzZwFt9wEPdxAFMRfBtLVZHd2UuTVzETkA1MDP1F97DwL6ZoFbMB2cSntsG7jGkN+Rz1X2g7pmP4gj+YbgE53iLmsejVFn738CEaNoWHe3lUaV6O4wUO4SN6SazMdzgZ07IWde4qScz/eGQwWxVZv95o56K2lzCJ87sAbOFXlP1KVWT94ZW5jmu4GLc4ussMlvGqKrKvtXJnANdcV/hwPLlyAAAAAElFTkSuQmCC" alt="ใบตรวจรางวัล" />
                          ใบตรวจรางวัล
                        </div>
                      </a>
                    </div>
                  </div>
                </div>
              </div> 
              <div className="row row2">
                <div className="col-12 d-flex flex-column flex-md-row">
                  <div className="award1">
                    <h2 className="hilight">รางวัลที่ 1</h2> 
                    <div className="award1-item">
                      <p className="award1-item-sub">
                      608662
                      </p>
                    </div>
                  </div> 
                  <div className="award2 d-flex flex-row flex-md-column">
                    <h2 className="label">รางวัลเลขหน้า 3 ตัว</h2> 
                    <div className="award2-item d-flex flex-row">
                      <p className="award2-item-sub">203</p>
                      <p className="award2-item-sub">904</p>
                    </div>
                  </div> 
                  <div className="award2 d-flex flex-row flex-md-column">
                    <h2 className="label">รางวัลเลขท้าย 3 ตัว</h2> 
                    <div className="award2-item d-flex flex-rowflex-row">
                      <p className="award2-item-sub">008</p>
                      <p className="award2-item-sub">048</p>
                    </div>
                  </div> 
                  <div className="award3 d-flex flex-row flex-md-column">
                    <h2 className="label">รางวัลเลขท้าย 2 ตัว</h2> 
                    <div className="award3-item">
                      <p className="award3-item-sub">37</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>


        


      <div className="margin"/>
      <LottoStat/>

      <div className="margin"/>
      <Routes>
        <Route exact path="/" element={<LottoHistory/>} />
        <Route path="/manage" element={<DataPage/>} />
        {/* เพิ่มเส้นทางอื่นๆ ที่นี่ */}
      </Routes>

      <div className="margin"/>
      <div className="container">
        <div className="row">
          <div className="col-12 after-award">
            <div className="award-current-box">
              <div className="row row1">
                <div className="col-12 col-md-6 col-lg-8">
                  <h1>ผลการออกรางวัลสลากกินแบ่งรัฐบาล</h1>
                  <h2>งวดประจำวันที่ 1 ตุลาคม 2567</h2>
                </div>
                <div className="col-12 col-md-6 col-lg-4 d-none d-md-block">
                  <div className="row button-home">
                    <div className="col-6">
                      <a href="/mission/reward-payment/check-reward" onClick={handleLinkClick}>
                        <div className="button-secoundary">
                          ตรวจผลรางวัล
                        </div>
                      </a>
                    </div>
                    <div className="col-6">
                      <a href="/award-pdf/current.pdf" target="_blank" rel="noopener noreferrer">
                        <div className="button-secoundary">
                          <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAWCAYAAADafVyIAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAIGNIUk0AAHolAACAgwAA+f8AAIDpAAB1MAAA6mAAADqYAAAXb5JfxUYAAAF4SURBVHja7NU9axVREIDhZ6/BZIVgwEJSWKiNImhjAnYiLIjYiIWlpLCxzULIP5CwpYIoiKUIIrZubyFXSEBUsLCJhUoQEd2L+Wxm4bLCZTfRLlPOmZn3zMc5k0zMv9RSEtzFA6zUyqrIRjr1tJfruI3lDj7GOtr+xGYXQJcMatn+3wD7gH3AX4ATMXo3GmcpbuLMCP/5NC9nmso0L9+nefmoBtwKwJOG3VU8xlucHvouar9nKPC6EfwCTmEuzcsjPQzC8U0D8Bzf4+X2cTgukuApLofdYsNvgB9Yx2BUDzZwFt9wEPdxAFMRfBtLVZHd2UuTVzETkA1MDP1F97DwL6ZoFbMB2cSntsG7jGkN+Rz1X2g7pmP4gj+YbgE53iLmsejVFn738CEaNoWHe3lUaV6O4wUO4SN6SazMdzgZ07IWde4qScz/eGQwWxVZv95o56K2lzCJ87sAbOFXlP1KVWT94ZW5jmu4GLc4ussMlvGqKrKvtXJnANdcV/hwPLlyAAAAAElFTkSuQmCC" alt="ใบตรวจรางวัล" />
                          ใบตรวจรางวัล
                        </div>
                      </a>
                    </div>
                  </div>
                </div>
              </div> 
              <div className="row row2">
                <div className="col-12 d-flex flex-column flex-md-row">
                  <div className="award1">
                    <h2 className="hilight">รางวัลที่ 1</h2> 
                    <div className="award1-item">
                      <p className="award1-item-sub">
                      XXXXXX
                      </p>
                    </div>
                  </div> 
                  <div className="award2 d-flex flex-row flex-md-column">
                    <h2 className="label">รางวัลเลขหน้า 3 ตัว</h2> 
                    <div className="award2-item d-flex flex-row">
                      <p className="award2-item-sub">XXX</p>
                      <p className="award2-item-sub">XXX</p>
                    </div>
                  </div> 
                  <div className="award2 d-flex flex-row flex-md-column">
                    <h2 className="label">รางวัลเลขท้าย 3 ตัว</h2> 
                    <div className="award2-item d-flex flex-rowflex-row">
                      <p className="award2-item-sub">XXX</p>
                      <p className="award2-item-sub">XXX</p>
                    </div>
                  </div> 
                  <div className="award3 d-flex flex-row flex-md-column">
                    <h2 className="label">รางวัลเลขท้าย 2 ตัว</h2> 
                    <div className="award3-item">
                      <p className="award3-item-sub">XX</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="margin"/>
      <div className="container">
        <div className="row">
          <div className="col-12 after-award">
            <div className="award-current-box">
              <div className="row row1">
                <div className="col-12 col-md-6 col-lg-8">
                  <h1>ผลการออกรางวัลสลากกินแบ่งรัฐบาล</h1>
                  <h2>งวดประจำวันที่ 16 ตุลาคม 2567</h2>
                </div>
                <div className="col-12 col-md-6 col-lg-4 d-none d-md-block">
                  <div className="row button-home">
                    <div className="col-6">
                      <a href="/mission/reward-payment/check-reward" onClick={handleLinkClick}>
                        <div className="button-secoundary">
                          ตรวจผลรางวัล
                        </div>
                      </a>
                    </div>
                    <div className="col-6">
                      <a href="/award-pdf/current.pdf" target="_blank" rel="noopener noreferrer">
                        <div className="button-secoundary">
                          <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAWCAYAAADafVyIAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAIGNIUk0AAHolAACAgwAA+f8AAIDpAAB1MAAA6mAAADqYAAAXb5JfxUYAAAF4SURBVHja7NU9axVREIDhZ6/BZIVgwEJSWKiNImhjAnYiLIjYiIWlpLCxzULIP5CwpYIoiKUIIrZubyFXSEBUsLCJhUoQEd2L+Wxm4bLCZTfRLlPOmZn3zMc5k0zMv9RSEtzFA6zUyqrIRjr1tJfruI3lDj7GOtr+xGYXQJcMatn+3wD7gH3AX4ATMXo3GmcpbuLMCP/5NC9nmso0L9+nefmoBtwKwJOG3VU8xlucHvouar9nKPC6EfwCTmEuzcsjPQzC8U0D8Bzf4+X2cTgukuApLofdYsNvgB9Yx2BUDzZwFt9wEPdxAFMRfBtLVZHd2UuTVzETkA1MDP1F97DwL6ZoFbMB2cSntsG7jGkN+Rz1X2g7pmP4gj+YbgE53iLmsejVFn738CEaNoWHe3lUaV6O4wUO4SN6SazMdzgZ07IWde4qScz/eGQwWxVZv95o56K2lzCJ87sAbOFXlP1KVWT94ZW5jmu4GLc4ussMlvGqKrKvtXJnANdcV/hwPLlyAAAAAElFTkSuQmCC" alt="ใบตรวจรางวัล" />
                          ใบตรวจรางวัล
                        </div>
                      </a>
                    </div>
                  </div>
                </div>
              </div> 
              <div className="row row2">
                <div className="col-12 d-flex flex-column flex-md-row">
                  <div className="award1">
                    <h2 className="hilight">รางวัลที่ 1</h2> 
                    <div className="award1-item">
                      <p className="award1-item-sub">
                      XXXXXX
                      </p>
                    </div>
                  </div> 
                  <div className="award2 d-flex flex-row flex-md-column">
                    <h2 className="label">รางวัลเลขหน้า 3 ตัว</h2> 
                    <div className="award2-item d-flex flex-row">
                      <p className="award2-item-sub">XXX</p>
                      <p className="award2-item-sub">XXX</p>
                    </div>
                  </div> 
                  <div className="award2 d-flex flex-row flex-md-column">
                    <h2 className="label">รางวัลเลขท้าย 3 ตัว</h2> 
                    <div className="award2-item d-flex flex-rowflex-row">
                      <p className="award2-item-sub">XXX</p>
                      <p className="award2-item-sub">XXX</p>
                    </div>
                  </div> 
                  <div className="award3 d-flex flex-row flex-md-column">
                    <h2 className="label">รางวัลเลขท้าย 2 ตัว</h2> 
                    <div className="award3-item">
                      <p className="award3-item-sub">XX</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="margin"/>
      <div className="container">
        <div className="row">
          <div className="col-12 after-award">
            <div className="award-current-box">
              <div className="row row1">
                <div className="col-12 col-md-6 col-lg-8">
                  <h1>ผลการออกรางวัลสลากกินแบ่งรัฐบาล</h1>
                  <h2>งวดประจำวันที่ 1 พฤศจิกายน 2567</h2>
                </div>
                <div className="col-12 col-md-6 col-lg-4 d-none d-md-block">
                  <div className="row button-home">
                    <div className="col-6">
                      <a href="/mission/reward-payment/check-reward" onClick={handleLinkClick}>
                        <div className="button-secoundary">
                          ตรวจผลรางวัล
                        </div>
                      </a>
                    </div>
                    <div className="col-6">
                      <a href="/award-pdf/current.pdf" target="_blank" rel="noopener noreferrer">
                        <div className="button-secoundary">
                          <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAWCAYAAADafVyIAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAIGNIUk0AAHolAACAgwAA+f8AAIDpAAB1MAAA6mAAADqYAAAXb5JfxUYAAAF4SURBVHja7NU9axVREIDhZ6/BZIVgwEJSWKiNImhjAnYiLIjYiIWlpLCxzULIP5CwpYIoiKUIIrZubyFXSEBUsLCJhUoQEd2L+Wxm4bLCZTfRLlPOmZn3zMc5k0zMv9RSEtzFA6zUyqrIRjr1tJfruI3lDj7GOtr+xGYXQJcMatn+3wD7gH3AX4ATMXo3GmcpbuLMCP/5NC9nmso0L9+nefmoBtwKwJOG3VU8xlucHvouar9nKPC6EfwCTmEuzcsjPQzC8U0D8Bzf4+X2cTgukuApLofdYsNvgB9Yx2BUDzZwFt9wEPdxAFMRfBtLVZHd2UuTVzETkA1MDP1F97DwL6ZoFbMB2cSntsG7jGkN+Rz1X2g7pmP4gj+YbgE53iLmsejVFn738CEaNoWHe3lUaV6O4wUO4SN6SazMdzgZ07IWde4qScz/eGQwWxVZv95o56K2lzCJ87sAbOFXlP1KVWT94ZW5jmu4GLc4ussMlvGqKrKvtXJnANdcV/hwPLlyAAAAAElFTkSuQmCC" alt="ใบตรวจรางวัล" />
                          ใบตรวจรางวัล
                        </div>
                      </a>
                    </div>
                  </div>
                </div>
              </div> 
              <div className="row row2">
                <div className="col-12 d-flex flex-column flex-md-row">
                  <div className="award1">
                    <h2 className="hilight">รางวัลที่ 1</h2> 
                    <div className="award1-item">
                      <p className="award1-item-sub">
                      XXXXXX
                      </p>
                    </div>
                  </div> 
                  <div className="award2 d-flex flex-row flex-md-column">
                    <h2 className="label">รางวัลเลขหน้า 3 ตัว</h2> 
                    <div className="award2-item d-flex flex-row">
                      <p className="award2-item-sub">XXX</p>
                      <p className="award2-item-sub">XXX</p>
                    </div>
                  </div> 
                  <div className="award2 d-flex flex-row flex-md-column">
                    <h2 className="label">รางวัลเลขท้าย 3 ตัว</h2> 
                    <div className="award2-item d-flex flex-rowflex-row">
                      <p className="award2-item-sub">XXX</p>
                      <p className="award2-item-sub">XXX</p>
                    </div>
                  </div> 
                  <div className="award3 d-flex flex-row flex-md-column">
                    <h2 className="label">รางวัลเลขท้าย 2 ตัว</h2> 
                    <div className="award3-item">
                      <p className="award3-item-sub">XX</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="margin"/>
      <div className="container">
        <div className="row">
          <div className="col-12 after-award">
            <div className="award-current-box">
              <div className="row row1">
                <div className="col-12 col-md-6 col-lg-8">
                  <h1>ผลการออกรางวัลสลากกินแบ่งรัฐบาล</h1>
                  <h2>งวดประจำวันที่ 16 พฤศจิกายน 2567</h2>
                </div>
                <div className="col-12 col-md-6 col-lg-4 d-none d-md-block">
                  <div className="row button-home">
                    <div className="col-6">
                      <a href="/mission/reward-payment/check-reward" onClick={handleLinkClick}>
                        <div className="button-secoundary">
                          ตรวจผลรางวัล
                        </div>
                      </a>
                    </div>
                    <div className="col-6">
                      <a href="/award-pdf/current.pdf" target="_blank" rel="noopener noreferrer">
                        <div className="button-secoundary">
                          <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAWCAYAAADafVyIAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAIGNIUk0AAHolAACAgwAA+f8AAIDpAAB1MAAA6mAAADqYAAAXb5JfxUYAAAF4SURBVHja7NU9axVREIDhZ6/BZIVgwEJSWKiNImhjAnYiLIjYiIWlpLCxzULIP5CwpYIoiKUIIrZubyFXSEBUsLCJhUoQEd2L+Wxm4bLCZTfRLlPOmZn3zMc5k0zMv9RSEtzFA6zUyqrIRjr1tJfruI3lDj7GOtr+xGYXQJcMatn+3wD7gH3AX4ATMXo3GmcpbuLMCP/5NC9nmso0L9+nefmoBtwKwJOG3VU8xlucHvouar9nKPC6EfwCTmEuzcsjPQzC8U0D8Bzf4+X2cTgukuApLofdYsNvgB9Yx2BUDzZwFt9wEPdxAFMRfBtLVZHd2UuTVzETkA1MDP1F97DwL6ZoFbMB2cSntsG7jGkN+Rz1X2g7pmP4gj+YbgE53iLmsejVFn738CEaNoWHe3lUaV6O4wUO4SN6SazMdzgZ07IWde4qScz/eGQwWxVZv95o56K2lzCJ87sAbOFXlP1KVWT94ZW5jmu4GLc4ussMlvGqKrKvtXJnANdcV/hwPLlyAAAAAElFTkSuQmCC" alt="ใบตรวจรางวัล" />
                          ใบตรวจรางวัล
                        </div>
                      </a>
                    </div>
                  </div>
                </div>
              </div> 
              <div className="row row2">
                <div className="col-12 d-flex flex-column flex-md-row">
                  <div className="award1">
                    <h2 className="hilight">รางวัลที่ 1</h2> 
                    <div className="award1-item">
                      <p className="award1-item-sub">
                      XXXXXX
                      </p>
                    </div>
                  </div> 
                  <div className="award2 d-flex flex-row flex-md-column">
                    <h2 className="label">รางวัลเลขหน้า 3 ตัว</h2> 
                    <div className="award2-item d-flex flex-row">
                      <p className="award2-item-sub">XXX</p>
                      <p className="award2-item-sub">XXX</p>
                    </div>
                  </div> 
                  <div className="award2 d-flex flex-row flex-md-column">
                    <h2 className="label">รางวัลเลขท้าย 3 ตัว</h2> 
                    <div className="award2-item d-flex flex-rowflex-row">
                      <p className="award2-item-sub">XXX</p>
                      <p className="award2-item-sub">XXX</p>
                    </div>
                  </div> 
                  <div className="award3 d-flex flex-row flex-md-column">
                    <h2 className="label">รางวัลเลขท้าย 2 ตัว</h2> 
                    <div className="award3-item">
                      <p className="award3-item-sub">XX</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="margin"/>
      <div className="container">
        <div className="row">
          <div className="col-12 after-award">
            <div className="award-current-box">
              <div className="row row1">
                <div className="col-12 col-md-6 col-lg-8">
                  <h1>ผลการออกรางวัลสลากกินแบ่งรัฐบาล</h1>
                  <h2>งวดประจำวันที่ 1 ธันวาคม 2567</h2>
                </div>
                <div className="col-12 col-md-6 col-lg-4 d-none d-md-block">
                  <div className="row button-home">
                    <div className="col-6">
                      <a href="/mission/reward-payment/check-reward" onClick={handleLinkClick}>
                        <div className="button-secoundary">
                          ตรวจผลรางวัล
                        </div>
                      </a>
                    </div>
                    <div className="col-6">
                      <a href="/award-pdf/current.pdf" target="_blank" rel="noopener noreferrer">
                        <div className="button-secoundary">
                          <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAWCAYAAADafVyIAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAIGNIUk0AAHolAACAgwAA+f8AAIDpAAB1MAAA6mAAADqYAAAXb5JfxUYAAAF4SURBVHja7NU9axVREIDhZ6/BZIVgwEJSWKiNImhjAnYiLIjYiIWlpLCxzULIP5CwpYIoiKUIIrZubyFXSEBUsLCJhUoQEd2L+Wxm4bLCZTfRLlPOmZn3zMc5k0zMv9RSEtzFA6zUyqrIRjr1tJfruI3lDj7GOtr+xGYXQJcMatn+3wD7gH3AX4ATMXo3GmcpbuLMCP/5NC9nmso0L9+nefmoBtwKwJOG3VU8xlucHvouar9nKPC6EfwCTmEuzcsjPQzC8U0D8Bzf4+X2cTgukuApLofdYsNvgB9Yx2BUDzZwFt9wEPdxAFMRfBtLVZHd2UuTVzETkA1MDP1F97DwL6ZoFbMB2cSntsG7jGkN+Rz1X2g7pmP4gj+YbgE53iLmsejVFn738CEaNoWHe3lUaV6O4wUO4SN6SazMdzgZ07IWde4qScz/eGQwWxVZv95o56K2lzCJ87sAbOFXlP1KVWT94ZW5jmu4GLc4ussMlvGqKrKvtXJnANdcV/hwPLlyAAAAAElFTkSuQmCC" alt="ใบตรวจรางวัล" />
                          ใบตรวจรางวัล
                        </div>
                      </a>
                    </div>
                  </div>
                </div>
              </div> 
              <div className="row row2">
                <div className="col-12 d-flex flex-column flex-md-row">
                  <div className="award1">
                    <h2 className="hilight">รางวัลที่ 1</h2> 
                    <div className="award1-item">
                      <p className="award1-item-sub">
                      XXXXXX
                      </p>
                    </div>
                  </div> 
                  <div className="award2 d-flex flex-row flex-md-column">
                    <h2 className="label">รางวัลเลขหน้า 3 ตัว</h2> 
                    <div className="award2-item d-flex flex-row">
                      <p className="award2-item-sub">XXX</p>
                      <p className="award2-item-sub">XXX</p>
                    </div>
                  </div> 
                  <div className="award2 d-flex flex-row flex-md-column">
                    <h2 className="label">รางวัลเลขท้าย 3 ตัว</h2> 
                    <div className="award2-item d-flex flex-rowflex-row">
                      <p className="award2-item-sub">XXX</p>
                      <p className="award2-item-sub">XXX</p>
                    </div>
                  </div> 
                  <div className="award3 d-flex flex-row flex-md-column">
                    <h2 className="label">รางวัลเลขท้าย 2 ตัว</h2> 
                    <div className="award3-item">
                      <p className="award3-item-sub">XX</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="margin"/>
      <div className="container">
        <div className="row">
          <div className="col-12 after-award">
            <div className="award-current-box">
              <div className="row row1">
                <div className="col-12 col-md-6 col-lg-8">
                  <h1>ผลการออกรางวัลสลากกินแบ่งรัฐบาล</h1>
                  <h2>งวดประจำวันที่ 16 ธันวาคม 2567</h2>
                </div>
                <div className="col-12 col-md-6 col-lg-4 d-none d-md-block">
                  <div className="row button-home">
                    <div className="col-6">
                      <a href="/mission/reward-payment/check-reward" onClick={handleLinkClick}>
                        <div className="button-secoundary">
                          ตรวจผลรางวัล
                        </div>
                      </a>
                    </div>
                    <div className="col-6">
                      <a href="/award-pdf/current.pdf" target="_blank" rel="noopener noreferrer">
                        <div className="button-secoundary">
                          <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAWCAYAAADafVyIAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAIGNIUk0AAHolAACAgwAA+f8AAIDpAAB1MAAA6mAAADqYAAAXb5JfxUYAAAF4SURBVHja7NU9axVREIDhZ6/BZIVgwEJSWKiNImhjAnYiLIjYiIWlpLCxzULIP5CwpYIoiKUIIrZubyFXSEBUsLCJhUoQEd2L+Wxm4bLCZTfRLlPOmZn3zMc5k0zMv9RSEtzFA6zUyqrIRjr1tJfruI3lDj7GOtr+xGYXQJcMatn+3wD7gH3AX4ATMXo3GmcpbuLMCP/5NC9nmso0L9+nefmoBtwKwJOG3VU8xlucHvouar9nKPC6EfwCTmEuzcsjPQzC8U0D8Bzf4+X2cTgukuApLofdYsNvgB9Yx2BUDzZwFt9wEPdxAFMRfBtLVZHd2UuTVzETkA1MDP1F97DwL6ZoFbMB2cSntsG7jGkN+Rz1X2g7pmP4gj+YbgE53iLmsejVFn738CEaNoWHe3lUaV6O4wUO4SN6SazMdzgZ07IWde4qScz/eGQwWxVZv95o56K2lzCJ87sAbOFXlP1KVWT94ZW5jmu4GLc4ussMlvGqKrKvtXJnANdcV/hwPLlyAAAAAElFTkSuQmCC" alt="ใบตรวจรางวัล" />
                          ใบตรวจรางวัล
                        </div>
                      </a>
                    </div>
                  </div>
                </div>
              </div> 
              <div className="row row2">
                <div className="col-12 d-flex flex-column flex-md-row">
                  <div className="award1">
                    <h2 className="hilight">รางวัลที่ 1</h2> 
                    <div className="award1-item">
                      <p className="award1-item-sub">
                      XXXXXX
                      </p>
                    </div>
                  </div> 
                  <div className="award2 d-flex flex-row flex-md-column">
                    <h2 className="label">รางวัลเลขหน้า 3 ตัว</h2> 
                    <div className="award2-item d-flex flex-row">
                      <p className="award2-item-sub">XXX</p>
                      <p className="award2-item-sub">XXX</p>
                    </div>
                  </div> 
                  <div className="award2 d-flex flex-row flex-md-column">
                    <h2 className="label">รางวัลเลขท้าย 3 ตัว</h2> 
                    <div className="award2-item d-flex flex-rowflex-row">
                      <p className="award2-item-sub">XXX</p>
                      <p className="award2-item-sub">XXX</p>
                    </div>
                  </div> 
                  <div className="award3 d-flex flex-row flex-md-column">
                    <h2 className="label">รางวัลเลขท้าย 2 ตัว</h2> 
                    <div className="award3-item">
                      <p className="award3-item-sub">XX</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      </header>
    </div>

    </Router>
  );
}

export default App;
